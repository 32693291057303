// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Grid } from "grommet"
import { useLayoutEffect } from "react"
import { Outlet } from "react-router-dom"
import { Help } from "../contextual-help"
import FlashMessage from "./flash-message"
import TaskBanner from "./task-banner"

export default function GlobalLayout() {
  useLayoutEffect(() => {
    // re-insert global header/footer on component mount to ensure it exists and is visible.
    // By default, init is only called on initial page load but not if route changes and
    // PageLayout is unmounted/remounted which destroys the elements constructed during init.
    try {
      window.HPEHFWSLOADER?.insertHF(window.hfwsHeader, window.hfwsFooter)
      window.HPEHF?.forceReInit()
    } catch (error) {
      console.error(error)
    }

    return () => {
      // init bails early if this is true, so set false when unmounted
      // as the element no longer exists and needs to be initialized again on mount
      if (window.HPEHF) {
        window.HPEHF.hfInitialized = false
      }
    }
  }, [])

  return (
    <Grid columns={["auto", "min-content"]} height="100vh">
      <Box fill overflow="auto">
        <div id="header" className="header" />
        <FlashMessage />
        <Outlet />
        <TaskBanner />
      </Box>
      <Help />
    </Grid>
  )
}
