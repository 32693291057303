// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import DsccRTM from "rtm-library"
import {
  RTM_CONNECTION_EVENTS,
  RTM_RESOURCE_EVENTS,
  SHELL_RTM,
  TOKEN_REFRESH_EVENT_TYPE,
} from "./events"
import authService from "./oauth/auth-service"
import { env } from "./utils/env"

export class RTMClient {
  #connectionChangeListeners = new Set()
  #rtm
  #status = "CLOSED"
  #subs = new Map()

  setup = () => {
    window.addEventListener(SHELL_RTM.INIT, this.handleInit)
    window.addEventListener(SHELL_RTM.CLEANUP, this.handleCleanup)

    return () => {
      window.removeEventListener(SHELL_RTM.INIT, this.handleInit)
      window.removeEventListener(SHELL_RTM.CLEANUP, this.handleCleanup)
    }
  }

  init = async () => {
    this.#status = "CONNECTING"
    if (this.#rtm) return this.cleanup

    const user = await authService.getUser()
    if (!user) {
      this.#status = "CLOSED"
      return
    }

    this.#rtm = new DsccRTM(env.REACT_APP_API_URL, user.access_token, {
      verbose: process.env.NODE_ENV === "development", // Logs cloud events sent/received as CustomEvents
    })

    // log any errors on initial RTM connection attempt
    // RTM will retry connection internally until successful
    await this.#rtm.connect().catch(console.error)

    // add event listeners even if initial connection attempt failed
    // this way we're ready to handle events if connection retries succeed
    window.addEventListener(
      TOKEN_REFRESH_EVENT_TYPE,
      this.#reauthenticateConnection
    )
    RTM_CONNECTION_EVENTS.forEach((event) => {
      window.addEventListener(event, this.#forwardConnectionEvent)
    })
    RTM_RESOURCE_EVENTS.forEach((rtmEventType) => {
      window.addEventListener(rtmEventType, this.#forwardResourceEvent)
    })

    this.#status = "OPEN"
    return this.cleanup
  }

  cleanup = async () => {
    this.#status = "CLOSING"
    RTM_CONNECTION_EVENTS.forEach((event) => {
      window.removeEventListener(event, this.#forwardConnectionEvent)
    })
    RTM_RESOURCE_EVENTS.forEach((rtmEventType) => {
      window.removeEventListener(rtmEventType, this.#forwardResourceEvent)
    })
    window.removeEventListener(
      TOKEN_REFRESH_EVENT_TYPE,
      this.#reauthenticateConnection
    )
    await this.#rtm?.disconnect().catch(console.error)
    this.#status = "CLOSED"
  }

  #forwardConnectionEvent = (event) => {
    this.#connectionChangeListeners.forEach((cb) => cb(event.detail))
  }

  #forwardResourceEvent = (event) => {
    this.#subs
      .get(event.detail.metadata.subscription)
      ?.forEach((cb) => cb(event.detail))
  }

  #reauthenticateConnection = async () => {
    const user = await authService.getUser()
    if (user?.access_token) {
      this.#rtm.refreshToken(user.access_token)
    }
  }

  subscribe = (resourceUris, callback) => {
    resourceUris.forEach((uri) => {
      if (this.#subs.has(uri)) {
        this.#subs.get(uri).add(callback)
      } else {
        this.#rtm.subscribe([uri])
        this.#subs.set(uri, new Set([callback]))
      }
    })
  }

  unsubscribe = (resourceUris, callback) => {
    resourceUris.forEach((uri) => {
      if (!this.#subs.has(uri)) return

      this.#subs.get(uri).delete(callback)

      if (this.#subs.get(uri).size === 0) {
        this.#subs.delete(uri)
        this.#rtm.unsubscribe([uri])
      }
    })
  }

  handleInit = () => {
    this.#status === "CLOSED" && this.init()
  }

  handleCleanup = () => {
    this.#status === "OPEN" && this.cleanup()
  }

  isConnected = () => {
    return this.#rtm.isConnected()
  }

  addConnectionChangeListener = (callback) => {
    this.#connectionChangeListeners.add(callback)
  }

  removeConnectionChangeListener = (callback) => {
    return this.#connectionChangeListeners.delete(callback)
  }
}

const rtmClient = new RTMClient()

export default rtmClient
