// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { MicroApp } from "../components"
import { DSCCMicroAppRoute } from "./shell-route"
import { routes } from "../shell-routes"

export function Dom() {
  return <DSCCMicroAppRoute route={routes["dom-fleet"]} outlet />
}
Dom.ApolloObject = () => <MicroApp route={routes["dom-apollo-object"]} />
Dom.Arcus = () => <MicroApp route={routes["dom-arcus"]} />
Dom.DataAccess = () => <MicroApp route={routes["dom-data-access"]} />
Dom.EdgeStack = () => <MicroApp route={routes["dom-edgestack"]} />
Dom.File = () => <MicroApp route={routes["dom-file"]} />
Dom.Fleet = () => <MicroApp route={routes["dom-fleet"]} />
Dom.Homefleet = () => <MicroApp route={routes["dom-homefleet"]} />
Dom.Nimble = () => <MicroApp route={routes["dom-nimble"]} />
Dom.ObjectStorage = () => <MicroApp route={routes["dom-objectStorage"]} />
Dom.Primera = () => <MicroApp route={routes["dom-primera"]} />
Dom.SDS = () => <MicroApp route={routes["dom-sds"]} />
