// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useFlags } from "launchdarkly-react-client-sdk"
import useAuthZ from "./use-authz"
import useSubscriptions from "./use-subscriptions"
import { isAccessible } from "../utils/accessible"

export default function useTiles(tileData) {
  // First obtain the users's authz permissions, subscription product categories, and LaunchDarkly flags.
  const { permissions, loading } = useAuthZ()
  const { subscriptions } = useSubscriptions()
  const userLDFlags = useFlags()

  const isTileVisible = (p) => {
    // Determine accessibility of the tile based on permissions, subscriptions, and LD flags.
    const { hasTileAccess } = isAccessible({
      userLDFlags,
      requiredLDFlag: p.flag,
      userPermissions: permissions,
      requiredPermissions: p.resources,
      userSubscriptions: subscriptions,
      requiredSubscriptions: p.subscriptions,
    })

    // A tile is visible if it's external or the user has access
    return p.type === "external" || hasTileAccess
  }

  const tiles = tileData.reduce((acc, p) => {
    // Check if the current tile should be visible
    if (isTileVisible(p)) {
      acc.push(p)
    }

    return acc
  }, [])

  return { loading, tiles }
}
