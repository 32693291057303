// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Outlet } from "react-router-dom"
import {
  AccessControl,
  DSCCHeader,
  MicroApp,
  PageLayout,
  ROPHeader,
} from "../components"

export function ShellRoute({ children, Header, title }) {
  return (
    <PageLayout header={<Header serviceTitle={title} />}>{children}</PageLayout>
  )
}

export function DSCCMicroAppRoute({ outlet = false, route }) {
  return (
    <ShellRoute Header={DSCCHeader} title={route.title}>
      <AccessControl requiredPermissions={route.resources} title={route.title}>
        {outlet ? <Outlet /> : <MicroApp route={route} />}
      </AccessControl>
    </ShellRoute>
  )
}

export function ROPMicroAppRoute({ route }) {
  return (
    <ShellRoute Header={ROPHeader} title={route.title}>
      <MicroApp route={route} />
    </ShellRoute>
  )
}
