// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

// Brand Prefix
const prefix = "dscc"

// Header
const headerIds = (key) => ({
  container: key,
  brand: `${key}-brand`,
  search: `${key}-search`,
  issues: { icon: `${key}-issues-icon`, tip: `${key}-issues-tip` },
  notifications: {
    icon: `${key}-notifications-icon`,
    plugin: `${key}-notifications-plugin`,
    tip: `${key}-notifications-tip`,
  },
  articles: { icon: `${key}-articles-icon`, tip: `${key}-articles-tip` },
  avatar: `${key}-avatar`,
})

// Mini Launchpad
const miniLaunchpadIds = (key) => ({
  button: `${key}-button`,
  box: `${key}-box`,
  grid: `${key}-grid`,
  item: `${key}-item`,
  loading: `${key}-loading`,
  title: `${key}-title`,
})

// User Profile Menu
const userProfileIds = (key) => ({
  container: key,
  name: `${key}-name`,
  logout: `${key}-logout`,
  picture: `${key}-picture`,
})

// Launchpad
const launchpadApps = [
  "allowDenyManager",
  "announcements",
  "audit",
  "atlas",
  "atlasRop",
  "block",
  "cloudPhysics",
  "clusterStatus",
  "dataObservability",
  "dom",
  "dualAuth",
  "email",
  "fileManager",
  "groups",
  "intentBasedProvisioning",
  "kubernetesService",
  "pcai",
  "pcbe",
  "objectStorage",
  "secrets",
  "setup",
  "sfm",
  "shapeshifter",
  "tasks",
  "vcf",
  "webhooks",
  "zerto",
]

const tileIds = (key) => ({
  button: `${key}-button`,
  container: key,
  description: `${key}-description`,
  icon: `${key}-icon`,
  title: `${key}-title`,
})

const toTileIds = (key) => (acc, app) => {
  acc[app] = tileIds(`${key}-${app}`)
  return acc
}

const launchpadIds = (key) => ({
  flash: `${key}-flash`,
  grid: `${key}-grid`,
  loading: `${key}-loading`,
  tiles: launchpadApps.reduce(toTileIds(key), {}),
  welcome: `${key}-welcome`,
})

// Additional Services
const additionalServicesIds = (key) => ({
  atlas: `${key}-atlas`,
  box: `${key}-box`,
  modal: `${key}-modal`,
})

// VCF
const vcfIds = (key) => ({
  title: `${key}-title`,
  paragraph: `${key}-paragraph`,
  grid: `${key}-grid`,
  tile: {
    title: `${key}-tile-title`,
    description: `${key}-tile-description`,
    button: `${key}-tile-button`,
  },
})

// Exports
export const mainContent = `${prefix}-main-content`
export const header = headerIds(`${prefix}-header`)
export const userProfile = userProfileIds(`${prefix}-user-profile`)
export const miniLaunchpad = miniLaunchpadIds(`${prefix}-mini-launchpad`)
export const launchpad = launchpadIds(`${prefix}-launchpad`)
export const additionalServices = additionalServicesIds(
  `${prefix}-additional-services`
)
export const vcf = vcfIds(`${prefix}-vcf`)
export const notFoundPage = `${prefix}-not-found-page`
export const contextualHelp = `${prefix}-contextual-help`
export const feedbackButton = `${prefix}-feedback-button`
export const taskBanner = `${prefix}-task-banner`
export const unauthorizedPage = `${prefix}-unauthorized-page`

// Test ID Attribute
export const testIdAttribute = "data-test-id"

export function getTestIdAttribute(value) {
  return value
    ? {
        [testIdAttribute]: value,
      }
    : {}
}
