// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box } from "grommet"
import FeedbackButton from "./feedback-button"
import { getTestIdAttribute, mainContent } from "../utils/test-ids"

export default function PageLayout({ children, footer, header }) {
  return (
    <Box direction="column" flex="grow">
      {header && header}
      <Box
        as="main"
        background="#f7f7f7"
        flex={{ grow: 98 }}
        {...getTestIdAttribute(mainContent)}
      >
        {children}
      </Box>
      <FeedbackButton />
      {footer && footer}
    </Box>
  )
}
