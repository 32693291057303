// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Navigate } from "react-router-dom"
import { useAuthZ } from "../hooks"
import { isAccessible } from "../utils/accessible"

export function AccessControl({
  children,
  requiredPermissions = [],
  title,
} = {}) {
  const { loading, permissions: userPermissions } = useAuthZ()
  const { hasPermissionAccess } = isAccessible({
    userPermissions,
    requiredPermissions,
  })

  const hasPermissionGate = requiredPermissions.length > 0

  // if permissions are required stay on page until permissions are loaded
  if (loading && hasPermissionGate) {
    return null
  }

  // redirect if authz finished loading and user does not have permission
  if (!loading && hasPermissionGate && !hasPermissionAccess) {
    return <Navigate to="/unauthorized" state={{ title }} replace />
  }

  return children
}
