// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { APP_INSTANCE_ID, testApplicationCustomerId } from "./constants"

const appCidMap = { [APP_INSTANCE_ID]: testApplicationCustomerId }
const testWorkspaceId = "test-workspace-id"

// mock route in place of ccsURL/login
export function CCSLogin() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    // application_instance_id sent by Data Services Cloud Console
    const appInstanceId = searchParams.get("app")

    // ccs login flow authenticates user who then selects their account
    const cid = appCidMap[appInstanceId]

    // ccs redirects to Data Services Cloud Console /login route with cid param
    if (cid) {
      navigate(`/login?cid=${cid}&workspace_id=${testWorkspaceId}`, {
        replace: true,
      })
    } else {
      navigate(`/unauthorized`)
    }
  }, [navigate, searchParams])

  return null
}
