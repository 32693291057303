// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { MicroApp } from "../components"
import { DSCCMicroAppRoute } from "./shell-route"
import { routes } from "../shell-routes"

export function Block() {
  return <DSCCMicroAppRoute route={routes.block} outlet />
}
Block.Arcus = () => <MicroApp route={routes["block-arcus"]} />
Block.Dashboard = () => <MicroApp route={routes.block} />
Block.DataAccess = () => <MicroApp route={routes["block-data-access"]} />
Block.EdgeStack = () => <MicroApp route={routes["block-edgestack"]} />
Block.Fleet = () => <MicroApp route={routes["block-fleet"]} />
Block.Nimble = () => <MicroApp route={routes["block-nimble"]} />
Block.Primera = () => <MicroApp route={routes["block-primera"]} />
