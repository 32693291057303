// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { ONPREM_API_CERT_KEY } from "../utils/constants"
import { env } from "../utils/env"

export function OnPremCertRedirect() {
  sessionStorage.setItem(ONPREM_API_CERT_KEY, true)

  if (process.env.NODE_ENV === "development") {
    window.location.replace("/")
    return null
  }

  window.location.replace(
    `${env.REACT_APP_API_URL}/data-services/v1alpha1/onprem-cert`
  )

  return null
}
