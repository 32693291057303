// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Box, Card, CardBody, Heading, Text } from "grommet"
import { FormLock } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { DSCCHeader } from "../components"
import { ShellRoute } from "./shell-route"
import { getTestIdAttribute, unauthorizedPage } from "../utils/test-ids"

const MultiLineText = styled(Text)`
  white-space: pre-line;
`

export function Unauthorized() {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <ShellRoute Header={DSCCHeader} title={location.state?.title}>
      <Card
        elevation="small"
        margin="20px"
        {...getTestIdAttribute(unauthorizedPage)}
      >
        <CardBody align="center" gap="medium" pad="large">
          <Heading>{t("unauthorizedTitle")}</Heading>
          <MultiLineText size="small" textAlign="center">
            {t("unauthorizedService")}
          </MultiLineText>
          <Box background="#F4F6F9" pad="34px" round="50px">
            <FormLock color="#CCCCCC" size="xlarge" />
          </Box>
        </CardBody>
      </Card>
    </ShellRoute>
  )
}
