// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

export const AuthZPermissions = ["ALL.read", "volume.create"]

export const FLAG_RTM = "shell-enable-rtm-1"

/**
 * Types of tiles.
 * @readonly
 * @enum {string}
 */
export const TileType = {
  SERVICE: "SERVICE",
  FEATURE: "FEATURE",
}

/**
 * List of Products
 * @readonly
 * @enum {string}
 */
export const Products = {
  DSCC: "DSCC",
  ONPREM: "ONPREM",
  ROP: "ROP",
}

export const ONPREM_API_CERT_KEY = "dscc-onprem-api-cert"
